import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";

import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "../components/Analytics";
import { useTracking } from "react-tracking";

import SmartFeedFilters from "../components/SmartFeedFilters";
import SmartFeedList from "../components/SmartFeedList";
import SmartFeedNavigation from "../components/SmartFeedNavigation";
import SmartFeedJobAlerts from "../components/SmartFeedJobAlerts";

import { useFiltersContext } from "../context/FiltersContext";

require("es6-promise").polyfill();

export default function VacanciesPage(props) {
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.urlLocation && props.urlLocation.search) {
    let searchParams = new URLSearchParams(props.urlLocation.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === "iframe") {
        iFrameView = pair[1];
      }
      if (pair[0] === "HideCareersLink") {
        HideCareersLink = pair[1];
      }
      if (pair[0] === "IsIntranet") {
        internalView = pair[1];
      }
    }
  }

  const [vacancyLoading, setVacancyLoading] = useState(true);

  const siteConfig = props.siteConfig;

  const companyName =
    siteConfig?.companyName || props?.smartfeedCompanyData?.CompanyName || "";

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    {
      page: "VacanciesPage",
      location: props.location,
      apiKey: props.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies["ap-signals-user-id"],
      sessionIDValue,
      siteConfig
    );
  }, []);

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: "postingtype",
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <div className="container py-3">
          <div className="row">
            <div className="col-12 mb-3">
              <SmartFeedNavigation
                active={filters?.postingtype || "Careers"}
                onNavChange={onNavChange}
              />
            </div>
          </div>
          {filters?.postingtype !== "Alerts" ? (
            <>
              <div className="row">
                <div className="col-12">
                  <SmartFeedFilters
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || props.apiKey}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 p-0">
                  <SmartFeedList
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || props.apiKey}
                    smartfeedCompanyData={
                      siteConfig.smartfeedCompanyData ||
                      props.smartfeedCompanyData
                    }
                    trackEvent={trackEvent}
                    vacancyLoading={vacancyLoading}
                    setVacancyLoading={setVacancyLoading}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <SmartFeedJobAlerts
                  siteConfig={siteConfig}
                  trackEvent={trackEvent}
                  apiKey={props.apiKey}
                  gdprKey={props.gdprKey}
                  ReturnToSearchURL={props.ReturnToSearchURL}
                  location={siteConfig.showLocationJA}
                  region={siteConfig.showRegionJA}
                  category={siteConfig.showCategoryJA}
                  industry={siteConfig.showIndustryJA}
                  jobType={siteConfig.showJobTypeJA}
                  jobTime={siteConfig.showJobTimeJA}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
