import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";

import fetchJsonp from "fetch-jsonp";

import HomepagePage from "../templates/Homepage";
import VacancyPage from "../templates/VacancyPage";
import VacanciesPage from "../templates/VacanciesPage";
import SmartFeedSavedList from "../components/SmartFeedSavedJobsList";
import Loader from "../components/Loader";

const Vacancies = (props) => {
  const {
    pageContext: { config },
    location,
  } = props;

  const siteConfig = config;

  const apiKey =
    props.smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID;
  const gdprKey =
    props.smartfeedID ||
    siteConfig.gdprKey ||
    siteConfig.apiKey ||
    siteConfig.smartfeedID;
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  useEffect(() => {
    if (apiKey) {
      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            "/CareerPage/" +
            "GetGroupDetails" +
            "?id=" +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function(response) {
            return response.json();
          })
          .then(function(jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                "/CareerPage/" +
                "GetApplicantGetwayURL" +
                "?companyId" +
                "=" +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function(response) {
                return response.json();
              })
              .then(function(appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function(ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, "error");
              });
          })
          .catch(function(ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, "error");
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, "error");
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError("No API key was provided.");
    }
  }, [apiKey, gdprKey, siteConfig.sfAPIEndpoint]);

  return (
    <>
      {smartfeedCompanyData?.CompanyName &&
      smartfeedCompanyData.CompanyName !== "DefaultCompany" ? (
        <Router>
          <Loader
            siteConfig={siteConfig}
            location={location}
            apiKey={apiKey}
            smartfeedCompanyData={smartfeedCompanyData}
            appGatewayURL={appGatewayURL}
            vacanciesRouteLoading={vacanciesRouteLoading}
            vacanciesRouteError={vacanciesRouteError}
            default
          />
          <VacancyPage
            siteConfig={siteConfig}
            location={location}
            apiKey={apiKey}
            smartfeedCompanyData={smartfeedCompanyData}
            appGatewayURL={appGatewayURL}
            vacanciesRouteLoading={vacanciesRouteLoading}
            vacanciesRouteError={vacanciesRouteError}
            path="/vacancies/vacancy/:id"
          />
          <VacancyPage
            siteConfig={siteConfig}
            location={location}
            apiKey={apiKey}
            smartfeedCompanyData={smartfeedCompanyData}
            appGatewayURL={appGatewayURL}
            vacanciesRouteLoading={vacanciesRouteLoading}
            vacanciesRouteError={vacanciesRouteError}
            path="/vacancies/vacancy/:id/:vacancyTitlePath"
          />
          <SmartFeedSavedList
            siteConfig={siteConfig}
            location={location}
            apiKey={apiKey}
            smartfeedCompanyData={smartfeedCompanyData}
            appGatewayURL={appGatewayURL}
            vacanciesRouteLoading={vacanciesRouteLoading}
            vacanciesRouteError={vacanciesRouteError}
            path="/vacancies/saved"
          />
          <VacanciesPage
            siteConfig={siteConfig}
            urlLocation={location}
            apiKey={apiKey}
            smartfeedCompanyData={smartfeedCompanyData}
            appGatewayURL={appGatewayURL}
            vacanciesRouteLoading={vacanciesRouteLoading}
            vacanciesRouteError={vacanciesRouteError}
            path="/vacancies/"
          />
        </Router>
      ) : (
        <Loader
          siteConfig={siteConfig}
          location={location}
          apiKey={apiKey}
          smartfeedCompanyData={smartfeedCompanyData}
          appGatewayURL={appGatewayURL}
          vacanciesRouteLoading={vacanciesRouteLoading}
          vacanciesRouteError={vacanciesRouteError}
          default
        />
      )}
    </>
  );
};

export default Vacancies;
