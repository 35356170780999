export function calculateSalary(
  Currency,
  SalaryFrom,
  SalaryTo,
  SalaryType,
  SalaryPeriod,
) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  let NewSalary;
  let NewSalaryFrom;
  let NewSalaryTo;

  let currencyFormatted = Currency;

  if (Currency === '&pound;') {
    currencyFormatted = '£';
  }

  if (SalaryTo === 0) {
    NewSalary = SalaryType;
  } else if (SalaryFrom === SalaryTo) {
    if (SalaryFrom >= 1000) {
      // Remove decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
    } else {
      // Add two decimals
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
    }
    NewSalary = NewSalaryFrom + ' ' + SalaryPeriod;
  } else {
    if (SalaryFrom >= 1000) {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
    } else {
      NewSalaryFrom =
        currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
    }
    if (SalaryTo >= 1000) {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
    } else {
      NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
    }

    NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo + ' ' + SalaryPeriod;
  }
  return NewSalary;
}

export function CheckJobHasNotExpired(JSONDate) {
  if (JSONDate) {
    let expDate = new Date(parseInt(JSONDate.substr(6), 10));
    let now = new Date();

    if (expDate > now) {
      return true;
    }

    return false;
  } else {
    return false;
  }
}

export function FormatDateTime(JSONDate) {
  if (JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = {};
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  } else {
    return JSONDate;
  }
}

export function generateGoogleJobsSchema(vacancy) {
  return {
    '@context': 'http://schema.org',
    '@type': 'JobPosting',
    baseSalary: calculateSalary(
      vacancy.Currency,
      vacancy.SalaryFrom,
      vacancy.SalaryTo,
      vacancy.SalaryType,
      vacancy.SalaryPeriod,
    ),
    jobBenefits: vacancy.Benefits,
    datePosted: FormatDateTime(vacancy.PostingDate),
    validThrough: FormatDateTime(vacancy.ExpiryDate),
    description: vacancy.Description,
    employmentType: vacancy.JobTypeTime,
    industry: vacancy.Industry,
    jobLocation: {
      '@type': 'Place',
      geo: {
        longitude: vacancy.Longitude,
        latitude: vacancy.Latitude,
        '@type': 'GeoCoordinates',
      },
      address: {
        '@type': 'PostalAddress',
        addressCountry: vacancy.Country,
        addressLocality: vacancy.Location,
        addressRegion: vacancy.Region,
        postalCode: vacancy.PostCode,
      },
    },
    occupationalCategory: vacancy.JobCategory,
    salaryCurrency: vacancy.Currency === '&pound;' ? 'GBP' : 'EUR',
    hiringOrganization: {
      '@type': 'Organization',
      name: vacancy.Company,
      logo: vacancy.CompanyLogoPath,
    },
    title: vacancy.JobTitle,
    workHours: vacancy.JobTypeTime,
  };
}

export function listenForIFrameHeight() {
  if (typeof window !== 'undefined') {
    window.addEventListener(
      'message',
      function (event) {
        let careersPageiFrame = null;

        if (document.getElementById('vf_iFrame_application_form')) {
          careersPageiFrame = document.getElementById(
            'vf_iFrame_application_form',
          );
        }

        if (
          careersPageiFrame !== null &&
          careersPageiFrame !== undefined &&
          careersPageiFrame !== 'undefined'
        ) {
          let careersPageEvent = event.data[0];
          let careersPageHeight = event.data[1];

          switch (careersPageEvent) {
            case 'setCareersPageHeight':
              let careersPageHeightExtra = careersPageHeight + 50;

              console.debug('cp height => ', careersPageHeightExtra);

              careersPageiFrame.height = careersPageHeightExtra;
              break;
          }
        }
      },
      false,
    );
  }
}

export function vfScrollToElement(element, to, duration) {
  if (duration <= 0) return;
  var difference = to - element.scrollTop;
  var perTick = (difference / duration) * 10;

  setTimeout(function () {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    window.scrollTo(element, to, duration - 10);
  }, 10);
}

export function animate(elem, style, unit, from, to, time, prop) {
  if (!elem) {
    return;
  }
  var start = new Date().getTime(),
    timer = setInterval(function () {
      var step = Math.min(1, (new Date().getTime() - start) / time);
      if (prop) {
        elem[style] = from + step * (to - from) + unit;
      } else {
        elem.style[style] = from + step * (to - from) + unit;
      }
      if (step === 1) {
        clearInterval(timer);
      }
    }, 25);
  if (prop) {
    elem[style] = from + unit;
  } else {
    elem.style[style] = from + unit;
  }
}
