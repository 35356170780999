import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";

import SavedJobsList from "../components/SavedJobsList";

import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "../components/Analytics";
import { useTracking } from "react-tracking";

require("es6-promise").polyfill();

export default function SmartFeedSavedList(props) {
  const [vacancySavedLoading, setVacancySavedLoading] = useState(true);

  const siteConfig = props.siteConfig;

  const companyName =
    siteConfig?.companyName || props?.smartfeedCompanyData?.CompanyName || "";

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    {
      page: "SmartFeedSavedList",
      location: props.location,
      apiKey: props.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies["ap-signals-user-id"],
      sessionIDValue,
      siteConfig
    );
  }, [cookies, props.location, sessionIDValue, siteConfig]);

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
    >
      {!props.vacanciesRouteLoading &&
        props.smartfeedCompanyData &&
        props.apiKey && (
          <>
            <SavedJobsList
              trackEvent={trackEvent}
              siteConfig={siteConfig}
              setVacancySavedLoading={setVacancySavedLoading}
              vacancySavedLoading={vacancySavedLoading}
              apiKey={siteConfig.apiKey || props.apiKey}
              smartfeedCompanyData={
                siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
              }
            />
          </>
        )}
    </Layout>
  );
}
